export const Content = [
  {
    category: "about",
    link: `${window.location.origin}/all`,
    name: "Me, Myself and I",
    excerpt: `I am Surej, a dynamic entrepreneur and visionary leader who has left an indelible mark on the business landscape. As the driving force behind Chemicaldaddy and the founder of Aalambana.`
  },
  {
    category: "about",
    link: `${window.location.origin}/`,
    name: "An overview of my skills and interests",
    excerpt: `Under my leadership, Chemical Daddy has become a prominent player in the chemical solutions industry, known for its diverse and high-quality offerings. Simultaneously, Aalambana, Surej's brainchild in the IT sector, has emerged as a global player, providing cutting-edge services and products that reflect my dedication to staying at the forefront of technological advancements.`
  },
  {
    category: "works",
    link: "http://www.aalambana.com",
    name: "Aalambana",
    excerpt: `Multifaceted business endeavors, showcasing the breadth and depth of contributions to the local economy `
  },
  {
    category: "works",
    link: "http://chemicaldaddy.com",
    name: "Chemical Daddy",
    excerpt: `Chemical Daddy has become a prominent player in the chemical solutions industry, known for its diverse and high-quality offerings.`,
  },

  // {
  //   category: "writing",
  //   link: "http://www.aalambana.comblog",
  //   name: "Advantages and disadvantages of SVG",
  //   excerpt: `Created in 1999, SVG has grown to become the most popular vector 
  //   image format for the web. This article will cover the advantages and disadvantages 
  //   of SVG (Scalable Vector Graphics) as a format for displaying images on the web. `,
  // },
  

  {
    category: "social",
    link: "https://www.linkedin.com/company/aalambana-net/",
    name: "LinkedIn",
    excerpt: `Connect and share experiences`
  },
  // {
  //   category: "social",
  //   link: "",
  //   name: "GitHub",
  //   excerpt: `View my projects and code.`
  // }
];
