import React, { useState } from "react";
import "./accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const AccordionComponent = () => {
  const classes = useStyles();

  return (
    <div className="accordion-container">
      <div className="accordion-sub-container">
        <div className={classes.root}>
          <h2 style={{ fontWeight: "normal", color: "#333" }}>
            People also ask
          </h2>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Who is Surej MA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              he meeting of two personalities is like the contact of two chemical substances: if there is any reaction, both are transformed.
              I am Surej, a dynamic entrepreneur and visionary leader who has left an indelible mark on the business landscape. As the driving force behind Chemical Daddy (http://chemicaldaddy.com/) and the founder of Aalambana, an innovative IT company, I have demonstrated an unwavering commitment to excellence and a passion for pushing the boundaries of what's possible.
    
    Known for a focus on quality, innovation, and sustainability, Im not just an entrepreneur but a trailblazer who has successfully navigated and adapted to the evolving market dynamics. Through strategic initiatives and a forward-thinking approach, Surej continues to shape the future of business in Qatar and beyond.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                How can i reach Surej MA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Connecting with Surej MA is a seamless process to unlock possibilities. As the key point of contact for Aalambana, Surej MA welcomes your inquiries and collaboration opportunities. Reach out today to explore innovative solutions, discuss your unique needs, or simply engage in a conversation that propels your vision forward. Your journey begins with a message or call to Surej MA – let's connect and transform ideas into impactful realities

              <ul>
                <li>Whatsapp : <a  href="https://wa.me/+97430074008" target="_blank" title="WhatsApp" >
                +97444369444	</a></li>
               
              </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Describe skills of Surej?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Surej MA possesses a captivating blend of skills that form the bedrock of his professional prowess. 
              Under my leadership, Chemical Daddy has become a prominent player in the chemical solutions industry, known for its diverse and high-quality offerings. Simultaneously, Aalambana, Surej's brainchild in the IT sector, has emerged as a global player, providing cutting-edge services and products that reflect my dedication to staying at the forefront of technological advancements.
              </Typography>
            </AccordionDetails>
          </Accordion>
         
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                What are your hobbies?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                I enjoy reading fiction stories and philosophy. 
                When I'm not reading for leisure, I spend my free time 
                writing about random stuff. I also like listening to music,
                especially jip hop, R&B and drill. My favorite artists are
                Juice WRLD, Lil Tecca, JayDaYoungan, Yungeen Ace, SL, 
                Meekz Manny and Fredo. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                Are you open for work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes. I am always open to work on innovate projects with 
                creative projects. 
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                What's the fastest way to reach you?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
               Definitely email. Contact me at mailto:hello@surejma.com.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>
                What motivates you?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Meeting deadlines, targets or goals. mentoring and coaching others, learning new things, 
              coming up with creative ideas to improve something, and making something new.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
