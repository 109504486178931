import Chemical from "../images/Chemicaldaddy.png";
import Aalambana from "../images/Aalambana.png";
import Aalamcrm from "../images/aalamcrm.jpeg";

export const ProjectsContent = [
  {
    link: "http://chemicaldaddy.com",
    img: Chemical,
    name: "Chemical Daddy",
    excerpt: `ChemicalDaddy.com is an online platform that connects chemical manufacturers with suppliers.`,
    tools: ""
  },
  {
    link: "http://aalamcrm.com",
    img: Aalamcrm,
    name: "AalamCRM",
    excerpt: `Aalamcrm is an all-in-one WhatsApp marketing tool that lets you use the WhatsApp Cloud APIs to reach out to your customers on WhatsApp.`,
    tools: ""
  },
  {
    link: "http://www.aalambana.com",
    img: Aalambana,
    name: "Aalambana",
    excerpt: `A website where Multifaceted business endeavors, showcasing the breadth and depth of contributions to the local economy`,
    tools: ""
  },
  
];
