import Chemical from "../images/Chemicaldaddy.png";
import Aalambana from "../images/Aalambana.png";
import jsIcon from "../images/info.png";
export const BlogContent = [
 
  {
    link: "http://chemicaldaddy.com",
    img: Chemical,
    icon:jsIcon,
    name: "Chemical Daddy",
    excerpt: `A website where people can simultaneously code and watch/read
    tutorials. It's a more convenient way to learn and practice
    how to code (with HTML, CSS and JavaScript) on the same page`,
    category: "Business",
    date: ""
  },

  {
    link: "http://www.aalambana.com",
    img: Aalambana,
    name: "Aalambana",
    excerpt: `A website where Multifaceted business endeavors, showcasing the breadth and depth of contributions to the local economy`,
    icon:jsIcon,
    category: "Business",
  },
];
