
import li from "../images/linkedIn-icon.png";
import Chemical from "../images/Chemicaldaddy.png";
import Aalambana from "../images/Aalambana.png";
import jsIcon from "../images/info.png";

export const ImagesContent = [
 
  {
    link: "http://chemicaldaddy.com",
    img: Chemical,
    name: "Chemical Daddy",
  },
  {
   
    link: "http://www.aalambana.com",
    img: Aalambana,
    name: "Aalambana",
  },
  {
    link: "https://www.linkedin.com/company/aalambana-net/?originalSubdomain=in",
    img: li,
    name: "LinkedIn - Connect and share experiences"
  },
];
